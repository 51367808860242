<template>
    <styled-interface hide-toolbar>
        <styled-card
            class="flex ma-3 xs12">
            <template #heading>
                Alerts
            </template>

            <template #action-buttons>
                <action-button
                    icon="refresh"
                    :disabled="loading"
                    @click="recheckAllAlerts">
                    Check all alerts
                </action-button>
            </template>

            <div ref="scrollTarget" />
            <div class="groups-picker">
                <div class="groups-picker__holder">
                    <groups-picker-global />
                </div>
            </div>
            <div
                v-if="!loading"
                class="mx-5 my-4 layout align-center">
                <div>
                    <v-text-field
                        v-model="dealerName"
                        append-icon="search"
                        label="Search Accounts"
                        single-line
                        hide-details
                        clearable
                        class="styled-field mr-4" />
                </div>

                <div>
                    <v-select
                        v-model="alertType"
                        label="Alert Types"
                        :items="alertTypes"
                        item-text="display_name"
                        item-value="id"
                        class="styled-field"
                        clearable />
                </div>
            </div>
            <v-data-table
                :headers="headers"
                :items="formattedAlerts"
                :loading="loading"
                :pagination.sync="pagination"
                :total-items="pagination.totalItems"
                :rows-per-page-items="[25, 50, 75]"
                :style="{
                    minWidth: '1000px'
                }"
                class="styled-table communication-central-table">
                <template #items="{ item, index }">
                    <tr
                        :class="[
                            (index % 2) ? 'row-even' : 'row-odd',
                        ]">
                        <td class="pa-3 text-xs-left">
                            <icon
                                name="circle"
                                :color="item.color"
                                size="16" />
                            {{ item.status.slice(0, 1).toUpperCase() + item.status.slice(1) }}
                        </td>
                        <td class="pa-3 text-xs-left">
                            <router-link
                                :to="{
                                    name: 'global-dashboard',
                                    params: {
                                        dealer_id: item.dealer.id
                                    }
                                }">
                                {{ item.dealer.name }}
                            </router-link>
                        </td>
                        <td class="pa-3 text-xs-left">
                            {{ item.time_ago }}
                        </td>
                        <td class="pa-3 text-xs-left">
                            <remote-content
                                :value="item.message"
                                :dealer="item.dealer"
                                :ticket-data="{
                                    type: 'Other',
                                    subject: item.type
                                }" />
                        </td>
                        <td class="pa-3 text-xs-left">
                            <p
                                v-for="(action, i) in item.actions"
                                :key="i"
                                class="action">
                                <remote-content-link
                                    :href="action.url"
                                    :dealer="item.dealer"
                                    :ticket-data="{
                                        type: 'Other',
                                        subject: item.type
                                    }">
                                    {{ action.title }}
                                </remote-content-link>
                            </p>
                        </td>
                        <td class="pa-3 text-xs-left">
                            <action-button
                                icon="refresh"
                                :loading="checkIfAlertIsRefreshing(item)"
                                @click="recheckAlert(item.dealer.id, item)">
                                Check alert
                            </action-button>
                        </td>
                    </tr>
                </template>
                <template slot="progress">
                    <loader />
                </template>
            </v-data-table>
        </styled-card>
    </styled-interface>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import GroupsPickerGlobal from '@/components/globals/GroupsPickerGlobal';
import RemoteContentLink from '@/components/globals/RemoteContentLink';
import RemoteContent from '@/components/globals/RemoteContent';
import StyledInterface from '@/components/globals/StyledInterface';
import StyledCard from '@/components/globals/StyledCard';
import Loader from '@/components/globals/Loader';
import Icon from '@/components/globals/Icon';
import ActionButton from '@/components/globals/ActionButton';
import moment from 'moment';
import { debounce } from 'lodash';

export default {
    name: 'CommunicationCentral',
    title: 'Alerts',
    components: {
        ActionButton,
        RemoteContentLink,
        GroupsPickerGlobal,
        RemoteContent,
        StyledInterface,
        StyledCard,
        Loader,
        Icon,
    },
    data() {
        return {
            noGroups: false,
            dealerName: '',
            loading: true,
            alerts: [],
            pagination: {
                sortBy: '',
                descending: true,
                rowsPerPage: 25,
                totalItems: 0,
            },
            alertType: null,
            alertTypes: [],
            headers: [
                {
                    text: 'Status',
                    value: 'level',
                    align: 'left',
                    width: '120px',
                    sortable: false,
                },
                {
                    text: 'Account',
                    value: 'dealers.name',
                    align: 'left',
                    width: '20%',
                    sortable: false,
                },
                {
                    text: 'Time Ago',
                    value: 'time_ago',
                    align: 'left',
                    width: '120px',
                    sortable: false,
                },
                {
                    text: 'Message',
                    value: 'message',
                    align: 'left',
                    width: '50%',
                    sortable: false,
                },
                {
                    text: 'Actions',
                    align: 'left',
                    width: '20%',
                    sortable: false,
                },
                {
                    text: '',
                    align: 'left',
                    width: '90px',
                    sortable: false,
                },
            ],
        };
    },
    computed: {
        ...mapState({
            currentDealer: state => state.dealer.currentDealer,
            currentAgency: (state) => state.agency.currentAgency,
            refreshingAlerts: (state) => state.monitoring.refreshingAlerts,
            selectedGroups: (state) => state.agency.selectedGroups,
        }),
        formattedAlerts() {
            return this.alerts.map(alert => {
                return {
                    color: alert.level === 'critical' ? 'red' : 'orange',
                    status: alert.level,
                    dealer: alert.dealer,
                    time_ago: moment.utc(alert.created_at.date).fromNow(),
                    type_id: alert.alert_type_id,
                    type: alert.alert_type?.display_name || '',
                    message: alert.message,
                    actions: alert.actions,
                };
            });
        },
        dealerId() {
            return this.$route.query.dealer_id;
        },
    },
    watch: {
        pagination() {
            this.loadAlerts();
            this.scrollToTop();
        },
        alertType() {
            this.loadAlerts();
        },
        selectedGroups: {
            handler() {
                this.checkGroupsAndLoad();
            },
            deep: true,
        },
        dealerName() {
            this.searchDealers();
        },
    },
    created() {
        if (this.dealerId) {
            this.dealerName = this.currentDealer.name;
        }

        this.checkGroupsAndLoad();
        this.loadAlertTypes();
    },
    methods: {
        ...mapActions([
            'refreshAlert',
        ]),
        scrollToTop() {
            this.$scrollTo(
                this.$refs.scrollTarget,
                500,
                {
                    offset: -150,
                },
            );
        },
        searchDealers: debounce(async function() {
            this.loadAlerts();
        }, 300),
        checkGroupsAndLoad() {
            if (this.selectedGroups.length && !this.selectedGroups.includes(null)) {
                this.noGroups = false;
            } else {
                this.noGroups = true;
            }
            this.loadAlerts();
        },
        async loadAlerts() {
            const selectedGroupsIds = this.selectedGroups.flatMap(group => {
                return group?.id ? group.id : [];
            });
            try {
                this.loading = true;

                const params = {
                    alertType: this.alertType,
                    agencyId: this.currentAgency.id,
                    selectedGroups: this.selectedGroups.length ? selectedGroupsIds : null,
                    paginationOptions: this.pagination,
                    dealerName: this.dealerName || '',
                };

                const response = await this.$apiRepository.getAgencyAlerts(params);

                this.alerts = response.data.data;
                this.pagination.totalItems = response.data.meta.total;

            } catch (error) {
                this.$flash('An error occurred while loading alerts', 'red');
            } finally {
                this.loading = false;
            }
        },
        async loadAlertTypes() {
            try {
                this.loading = true;

                const { data } = await this.$apiRepository.getAlertTypes();

                this.alertTypes = data;
            } catch (error) {
                this.$flash('An error occurred while loading alert types', 'red');
            } finally {
                this.loading = false;
            }
        },
        checkIfAlertIsRefreshing(alert) {
            return this.refreshingAlerts.findIndex(refreshing => refreshing.dealerId == alert.dealer.id && refreshing.alertTypeId == alert.type_id) > -1;
        },
        async recheckAlert(dealerId, alert) {
            await this.refreshAlert({ dealerId, alertTypeId: alert.type_id });
            this.loadAlerts();
        },
        async recheckAllAlerts() {
            if (confirm('This could take a while, are you sure you want to re-check all alerts?')) {
                const requests = this.formattedAlerts.map(alert => {
                    return this.refreshAlert({
                        dealerId: alert.dealer.id,
                        alertTypeId: alert.type_id,
                    });
                });
                await Promise.all(requests);
                this.loadAlerts();

            }
        },
    },

};
</script>

<style lang="scss">
.communication-central-table-header {
    margin: 1.5rem 3rem;
}

.communication-central-table {
    .v-table__overflow {
        margin: 0 auto 30px;
        padding: 0 5px;
        width: calc(100% - 70px);
    }
}
.groups-picker {
    border-bottom: 1px solid $gray-light;
    padding: 15px 47px;
    &__holder {
        max-width: 255px;
    }
}
</style>
