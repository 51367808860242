var render = function render(){var _vm=this,_c=_vm._self._c;return _c('styled-interface',{attrs:{"hide-toolbar":""}},[_c('styled-card',{staticClass:"flex ma-3 xs12",scopedSlots:_vm._u([{key:"heading",fn:function(){return [_vm._v(" Alerts ")]},proxy:true},{key:"action-buttons",fn:function(){return [_c('action-button',{attrs:{"icon":"refresh","disabled":_vm.loading},on:{"click":_vm.recheckAllAlerts}},[_vm._v(" Check all alerts ")])]},proxy:true}])},[_c('div',{ref:"scrollTarget"}),_c('div',{staticClass:"groups-picker"},[_c('div',{staticClass:"groups-picker__holder"},[_c('groups-picker-global')],1)]),(!_vm.loading)?_c('div',{staticClass:"mx-5 my-4 layout align-center"},[_c('div',[_c('v-text-field',{staticClass:"styled-field mr-4",attrs:{"append-icon":"search","label":"Search Accounts","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.dealerName),callback:function ($$v) {_vm.dealerName=$$v},expression:"dealerName"}})],1),_c('div',[_c('v-select',{staticClass:"styled-field",attrs:{"label":"Alert Types","items":_vm.alertTypes,"item-text":"display_name","item-value":"id","clearable":""},model:{value:(_vm.alertType),callback:function ($$v) {_vm.alertType=$$v},expression:"alertType"}})],1)]):_vm._e(),_c('v-data-table',{staticClass:"styled-table communication-central-table",style:({
                minWidth: '1000px'
            }),attrs:{"headers":_vm.headers,"items":_vm.formattedAlerts,"loading":_vm.loading,"pagination":_vm.pagination,"total-items":_vm.pagination.totalItems,"rows-per-page-items":[25, 50, 75]},on:{"update:pagination":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"items",fn:function({ item, index }){return [_c('tr',{class:[
                        (index % 2) ? 'row-even' : 'row-odd',
                    ]},[_c('td',{staticClass:"pa-3 text-xs-left"},[_c('icon',{attrs:{"name":"circle","color":item.color,"size":"16"}}),_vm._v(" "+_vm._s(item.status.slice(0, 1).toUpperCase() + item.status.slice(1))+" ")],1),_c('td',{staticClass:"pa-3 text-xs-left"},[_c('router-link',{attrs:{"to":{
                                name: 'global-dashboard',
                                params: {
                                    dealer_id: item.dealer.id
                                }
                            }}},[_vm._v(" "+_vm._s(item.dealer.name)+" ")])],1),_c('td',{staticClass:"pa-3 text-xs-left"},[_vm._v(" "+_vm._s(item.time_ago)+" ")]),_c('td',{staticClass:"pa-3 text-xs-left"},[_c('remote-content',{attrs:{"value":item.message,"dealer":item.dealer,"ticket-data":{
                                type: 'Other',
                                subject: item.type
                            }}})],1),_c('td',{staticClass:"pa-3 text-xs-left"},_vm._l((item.actions),function(action,i){return _c('p',{key:i,staticClass:"action"},[_c('remote-content-link',{attrs:{"href":action.url,"dealer":item.dealer,"ticket-data":{
                                    type: 'Other',
                                    subject: item.type
                                }}},[_vm._v(" "+_vm._s(action.title)+" ")])],1)}),0),_c('td',{staticClass:"pa-3 text-xs-left"},[_c('action-button',{attrs:{"icon":"refresh","loading":_vm.checkIfAlertIsRefreshing(item)},on:{"click":function($event){return _vm.recheckAlert(item.dealer.id, item)}}},[_vm._v(" Check alert ")])],1)])]}}])},[_c('template',{slot:"progress"},[_c('loader')],1)],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }