<template>
    <v-autocomplete
        v-model="selectedGroups"
        :items="groups"
        :loading="groupsLoading ? 'primary' : false"
        :disabled="groupsLoading || disabled"
        class="agency-select styled-select light-theme-style"
        :menu-props="{ contentClass: 'groups-dropdown' }"
        label="Groups"
        return-object
        item-value="id"
        item-text="name"
        multiple
        :placeholder="placeholder"
        @blur="changeGroups">
        <template #prepend-item>
            <div
                class="select-all">
                <div class="checkbox-wrapper">
                    <v-checkbox
                        v-model="allAccounts"
                        color="primary" />
                </div>
                <div class="text-wrapper">
                    <span>All Accounts</span>
                </div>
            </div>
            <v-divider />
            <div
                v-if="groups.length"
                class="select-all">
                <div class="checkbox-wrapper">
                    <v-checkbox
                        v-model="allSelected"
                        :color="someSelected ? 'primary' : ''"
                        :indeterminate="someSelected && !allSelected" />
                </div>
                <div class="text-wrapper">
                    <span>All Groups</span>
                </div>
            </div>
            <v-divider />
        </template>

        <template #selection="{ item, index }">
            <span v-if="item && index === 0">
                <span v-if="selectedGroups.length === 1">{{ item.name }}</span>
                <span v-else-if="selectedGroups.length === groups.length">All Groups</span>
                <span v-else>{{ selectedGroups.length }} selected</span>
            </span>
        </template>

        <template #no-data>
            <v-list-tile>
                <v-list-tile-title>
                    {{ (groupsLoading) ? 'Loading...' : 'No groups found' }}
                </v-list-tile-title>
            </v-list-tile>
        </template>
    </v-autocomplete>
</template>

<script>
import { mapState } from 'vuex';

export default {
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            allAccounts: true,
            selectedGroups: []
        };
    },
    computed: {
        ...mapState({
            currentGroups: (state) => state.agency.selectedGroups,
            groups: (state) => state.agency.groups,
            groupsLoading: (state) => state.agency.groupsLoading,
            currentAgency: (state) => state.agency.currentAgency,
        }),
        allSelected: {
            set() {
                this.toggleAll();
            },
            get() {
                return this.selectedGroups.length === this.groups.length;
            }
        },
        someSelected() {
            return this.selectedGroups.length > 0;
        },
        placeholder() {
            return this.someSelected ? '' : 'All Accounts';
        }
    },
    watch: {
        currentGroups() {
            this.selectedGroups = this.currentGroups;
        },
        currentAgency() {
            this.$store.dispatch('setSelectedGroups', []);
            this.$store.dispatch('getGroups', this.currentAgency.id);
        },
        someSelected() {
            if (this.someSelected) {
                this.allAccounts = false;
            }
        },
        allAccounts() {
            if (this.allAccounts && this.someSelected) {
                this.selectedGroups = [];
            }
        }
    },
    mounted() {
        if (this.currentGroups.length && !this.currentGroups.includes(null)) {
            this.selectedGroups = this.currentGroups;
        } else if (this.currentGroups.includes(null)) {
            this.$store.dispatch('getGroups', this.currentAgency.id);
        }
    },
    methods: {
        toggleAll() {
            if (this.allSelected) {
                this.selectedGroups = [];
                this.allAccounts = true;
            } else {
                this.selectedGroups = this.groups.slice();
                this.allAccounts = false;
            }
        },
        changeGroups(e) {
            if (e instanceof PointerEvent) {
                if (this.selectedGroups.length === 0) {
                    this.allAccounts = true;
                    if (this.currentGroups.length) {
                        this.$store.dispatch('setSelectedGroups', []);
                    }
                }
                if (this.selectedGroups.length > 0) {
                    this.allAccounts = false;
                    this.$store.dispatch('setSelectedGroups', this.selectedGroups);
                }
            }
        },
    }
};
</script>

<style lang="scss" scoped>
.select-all {
    display: flex;
    padding: 0 16px;
    color: #757575;
    height: 50px;
}
.checkbox-wrapper {
    width: 40px;
}
.text-wrapper {
    width: calc(100% - 40px);
    display: flex;
    align-items: center;
    font-size: 16px;
}
</style>
<style lang="scss">
.select-all {
    .v-input--selection-controls {
        margin-top: 8px;
    }
}
.groups-dropdown {
    .v-list__tile {
        height: 40px;
    }
    .v-list__tile__action {
        min-width: 40px;
    }
    .v-list__tile__content {
        color: #757575;
    }
}
</style>

